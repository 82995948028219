<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="questions_table" :page="page" :parent_module_id="record.id" relation_name="formquestions" @create="createQuestion" @edit="editQuestion" @change="refresh"/>
<!--      AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS-->
<!--      <base-table-includable ref="categories_table" :page="page" :parent_module_id="record.id" relation_name="questioncategories" @create="createCategory" @edit="editCategory" @change="refresh"/>-->
        </v-expansion-panels>
      </v-col>
    </v-row>
    <create-formquestion ref="create_question" modal_name="create_question" @refresh="load" :parent="record" :categories="categories"/>
    <edit-formquestion ref="edit_question" modal_name="edit_question" @refresh="load" :parent="record" :categories="categories"/>
<!--AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS-->
<!--<create-questioncategory ref="create_category" modal_name="create_category" @refresh="getCategories"/>-->
<!--<edit-questioncategory ref="edit_category" modal_name="edit_category" @refresh="getCategories"/>-->
  </v-container>
</template>

<script>
import CreateFormquestion from "./formquestions/Create";
import EditFormquestion from "./formquestions/Edit";
//import CreateQuestioncategory from "./questioncategories/Create";
//import EditQuestioncategory from "./questioncategories/Edit";
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";

export default {
  components: {
    BaseTableIncludable,
    BaseTabPanel,
    BaseBreadcrumb,
    CreateFormquestion,
    EditFormquestion,
    //CreateQuestioncategory,
    //EditQuestioncategory
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("edit_form"),
        name: "forms",
        model: "form",
      },
      record: {
        name: null,
        description: null,
      },
      categories: [],
      usual_fields: [],
      tags_required: false,
      loading: false,
    };
  },
  created() {
    this.getCategories();
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
          .then((response) => {
            this.record = response.data;
            this.updateIncludableTables();
            this.getFields();
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateIncludableTables() {
      this.$refs.questions_table.records = this.$lodash.sortBy(this.record.questions, "sort_order");
      //this.$refs.categories_table.records = this.categories;
      this.$refs.questions_table.updateHeaders('form_question', null);
      //this.$refs.categories_table.updateHeaders('question_category', null);
      this.$refs.questions_table.selected = [];
      //this.$refs.categories_table.selected = [];
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.load();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
                  .then(() => {
                    this.$router.push("/" + this.page.name);
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          }
      );
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    refresh() {
      this.getCategories();
      this.load();
    },
    async getCategories() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/questioncategories")
          .then((response) => {
            this.categories = response.data;
            this.updateIncludableTables();
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    createQuestion() {
      this.$refs.create_question.openModal('create_question');
    },
    editQuestion(item) {
      this.$refs.edit_question.openModal('edit_question');
      this.$refs.edit_question.load(item.id);
    },
    //AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS
    /*createCategory() {
      this.$refs.create_category.openModal('create_category');
    },
    editCategory(item) {
      this.$refs.edit_category.openModal('edit_category');
      this.$refs.edit_category.load(item.id);
    },*/
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>